import * as React from "react";
import dayjs from "dayjs";

type Props = {
  dateString: string;
};
const CalendarTile: React.FC<Props> = ({ dateString }) => {
  const date = dayjs(dateString);
  return (
    <div className="mx-5 h-20 w-20 rounded-xl bg-amber-500 py-3 text-center text-orange-900">
      <span className="block text-3xl">{date.format("DD")}</span>
      <span className="block text-base font-bold">{date.format("ddd")}</span>
    </div>
  );
};
export default CalendarTile;