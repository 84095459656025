import { NotionEvent } from "@common/types/notion";
import { CryptoEvent } from "@components/Modules/wissen/event-calendar/event-container";

export const normalizeCryptoEvents = (node: NotionEvent) => {
  const tags = node.properties.Tags;
  const event: CryptoEvent = {
    title: node.title,
    tags: tags?.value?.map((tag) => tag.name) || [],
    url: node.properties.URL?.value,
    location: node.properties.Location?.value,
    startDate: node.properties.Date?.value?.start,
    endDate: node.properties.Date?.value?.end,
  };
  return event;
};
