import Layout from "@components/layout";
import EventContainer from "@components/Modules/wissen/event-calendar/event-container";
import SEO from "@components/seo";
import Button from "@components/UI-Elements/Button";
import { Section } from "@components/UI-Elements/General";
import TagButton from "@components/UI-Elements/Tag/TagButton";
import Title from "@components/UI-Elements/Typography/Title";
import React from "react";
import { useFilters } from "../../common/hooks/useFilters";

const filters = ["Bitcoin", "FED", "Konferenz"];
const title = "Der Krypto-Kalender";
const description = "Alle wichtigen Termine auf einem Blick.";
const CryptoLinks: React.FC = () => {
  const { filter, onSelect } = useFilters({ allowMultiple: false });

  return (
    <Layout prose>
      <SEO title={title} description={description} />
      <Section>
        <div className="text-center">
          <Title>{title}</Title>
          <p>{description}</p>
          <div className="flex justify-between">
            <div className="my-1">
              <strong>Events filtern:</strong>
              {filters.map((item) => (
                <TagButton
                  key={item}
                  title={item}
                  onClick={onSelect}
                  selected={filter.includes(item)}
                  showClose
                />
              ))}
            </div>
            <div>
              <Button href="https://links.mathias.rocks/ec-event" external>Event vorschlagen</Button>
            </div>
          </div>
        </div>
        <EventContainer filter={filter} />
      </Section>
    </Layout>
  );
};

export default CryptoLinks;
