import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { AllNotion, Edge } from "@common/types/notion";
import {
  filterByStartDate,
  filterByTag,
} from "@common/helpers/notion/notion-utils";
import EventCard from "./event-card";
import { groupBy } from "lodash";
import Title from "@components/UI-Elements/Typography/Title";
import dayjs from "dayjs";
import { normalizeCryptoEvents } from "@common/helpers/normalizers/notion-crypto-event";
import { sortByStartDate } from "@common/helpers/notion/crypto-event-utils";

type Props = {
  filter?: string[];
};
export type CryptoEvent = {
  title?: string;
  tags?: string[];
  url?: string;
  location?: string;
  startDate?: string;
  endDate?: string;
};
const EventContainer: React.FC<Props> = ({ filter }) => {
  const data: AllNotion = useStaticQuery(
    graphql`
      query CryptoEvents {
        allNotion(filter: { properties: { EC: { value: { eq: true } } } }) {
          edges {
            node {
              title
              properties {
                URL {
                  value
                }
                Location {
                  value
                }
                Date {
                  value {
                    start
                    end
                  }
                }
                Tags {
                  value {
                    name
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const events: CryptoEvent[] = data.allNotion.edges
    .map((edge: Edge) => edge.node)
    .filter(filterByTag(filter[0]))
    .filter(filterByStartDate(new Date()))
    .map(normalizeCryptoEvents);

  const groupedEvents = groupBy(events, (event) => {
    return dayjs(event.startDate).format("YYYY-MM");
  });

  return (
    <div className="grid gap-5">
      {Object.keys(groupedEvents)
        .sort()
        .map((monthKey) => (
          <React.Fragment key={monthKey}>
            <Title level={3} className="mb-0 mt-3">
              {dayjs(monthKey).format("MMMM YYYY")}
            </Title>
            {groupedEvents[monthKey]
              .sort(sortByStartDate)
              .map((event: CryptoEvent, index: number) => (
                <EventCard key={index} member={event} />
              ))}
          </React.Fragment>
        ))}
    </div>
  );
};

export default EventContainer;
