import * as React from "react";
import Title from "@components/UI-Elements/Typography/Title";
import { CryptoEvent } from "./event-container";
import Card from "@components/UI-Elements/Card";
import { AiOutlineCalendar } from "@react-icons/all-files/ai/AiOutlineCalendar";
import { AiOutlineEnvironment } from "@react-icons/all-files/ai/AiOutlineEnvironment";
import Button from "@components/UI-Elements/Button";
import { renderDateRange } from "@common/helpers/date-helper";
import CalendarTile from "@components/Core/UI-Elements/date/calendar-tile";

type Props = {
  member?: CryptoEvent;
};

const EventCard: React.FC<Props> = ({ member }) => {
  return (
    <Card prose={false}>
      <div className="flex">
        <div className="flex-none">
          <CalendarTile dateString={member.startDate} />
        </div>
        <div className="flex-auto prose dark:prose-invert">
          <Title level={4} align="left" className="!text-xl">{member.title}</Title>
          <p className="my-2">
            <AiOutlineCalendar className="inline mb-1"/> {renderDateRange(member)}{member.location ? ' | ' : ''} <AiOutlineEnvironment className="inline mb-1"/> {member.location}
          </p>
        </div>
        <div className="flex-auto text-right">
          <Button href={member.url} type="button" external>
            Zum Event
          </Button>
        </div>
      </div>
    </Card>
  );
};
export default EventCard;
